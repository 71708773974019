<template>
    <div class="user_info">
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <div class="grid_col_3">
                <a-form-model-item
                    ref="last_name"
                    class="w-full mb-2"
                    :label="$t('auth.label_last_name')"
                    prop="last_name">
                    <a-input
                        v-model="form.last_name"
                        class="w-full"
                        :placeholder="$t('auth.placeholder_last_name')"
                        size="large"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>
                <a-form-model-item
                    ref="first_name"
                    class="w-full mb-2"
                    :label="$t('auth.label_first_name')"
                    prop="first_name">
                    <a-input
                        v-model="form.first_name"
                        class="w-full"
                        :placeholder="$t('auth.placeholder_first_name')"
                        size="large"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>
                <a-form-model-item
                    ref="middle_name"
                    class="w-full mb-2"
                    :label="$t('auth.label_middle_name')"
                    prop="middle_name">
                    <a-input
                        v-model="form.middle_name"
                        class="w-full"
                        :placeholder="$t('auth.placeholder_middle_name')"
                        size="large"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>
            </div>
            <div class="grid_col_2">
                <a-form-model-item
                    ref="phone"
                    class="w-full mb-2"
                    :label="$t('auth.label_phone')"
                    prop="phone">
                    <a-input
                        v-model="form.phone"
                        :placeholder="$t('auth.placeholder_phone')"
                        class="w-full"
                        size="large"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>
                <a-form-model-item
                    ref="job_title"
                    class="w-full mb-2"
                    :label="$t('auth.label_job_title')"
                    prop="job_title">
                    <a-input
                        v-model="form.job_title"
                        :placeholder="$t('auth.placeholder_job_title')"
                        class="w-full"
                        size="large"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>

                <a-form-model-item
                    ref="agreement"
                    class="w-full mb-2"
                    prop="agreement">
                    <a-checkbox v-model="form.agreement">
                        {{ $t('auth.agreement_text') }} <a :href="authConfig.agreement" target="_blank">{{ $t('auth.agreement_link') }}</a>
                    </a-checkbox>
                </a-form-model-item>
            </div>

            <div class="buttons_grid">
                <a-button 
                    size="large"
                    @click="$store.commit('user/SET_REG_STEP', 2)">
                    {{ $t('auth.button_back') }}
                </a-button>
                <a-button
                    class="button_next"
                    type="primary"
                    ghost
                    :loading="loading"
                    size="large"
                    @click="formSubmit()">
                    {{ $t('auth.button_register') }}
                </a-button>
            </div>
        </a-form-model>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        regForm: {
            type: Object,
            default: () => {}
        },
        saveForm: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            dbList: state => state.dbList,
            serverType: state => state.serverType,
            pushAuth: state => state.pushAuth,
            config: state => state.config.config
        }),
        authConfig() {
            return this.$store.state.user.authConfig
        },
    },
    data() {
        return {
            loading: false,
            nextLoading: false,
            form: {
                first_name: '',
                last_name: '',
                middle_name: '',
                phone: '',
                post: '',
                contractor_name: 'Заглушка',
                job_title: '',
                agreement: false
            },
            rules: {
                last_name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' }
                ],
                first_name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' }
                ],
                middle_name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' }
                ],
                phone: [
                    {
                        pattern: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
                        message: (this.$t('num_error')), trigger: 'change'
                    },
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                ],
                agreement: [
                    { required: true, message: this.$t('field_required'), trigger: 'change', 
                        validator: (rule, value, callback) => value },
                ]
            }
        }
    },
    methods: {
        nextStep() {
            location.reload()
        },
        formSubmit() {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const queryData = JSON.parse(JSON.stringify(this.form))

                        let response = null
                        let data = null

                        const formData = {
                            ...this.regForm,
                        }
                        formData.password = this.saveForm.password
                        formData.password_confirm = this.saveForm.password_confirm

                        formData.first_name = queryData.first_name 
                        formData.last_name = queryData.last_name 
                        formData.middle_name = queryData.middle_name 

                        response = await this.$http.post('/users/register/', formData)
                        data = response.date
                        if(data) {
                            localStorage.setItem('user', JSON.stringify(data))
                            this.$store.commit('user/SET_USER', data)
                            this.resetRegForm()
                            // this.$store.commit('user/SET_REG_STEP', 3)
                        }
                        response = await this.$http.post('/users/intro/', queryData)
                        data = response.data
                        if(data) {
                            localStorage.setItem('user', JSON.stringify(data))
                            this.$store.commit('user/SET_USER', data)
                            this.$store.commit('user/SET_REG_STEP', 4)
                        }
                    } catch(error) {
                        console.log(error)
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        if(!error.message) {
                            this.$message.error(JSON.stringify(error), 4)
                        }
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$breakpoint-1: 828px;

.grid_col_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(1, 1fr);
    }

}
.grid_col_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(1, 1fr);
    }

}
.employees_radio{
    &::v-deep{
        .ant-radio-wrapper{
            .ant-radio{
                display: none;
            }
            span{
                padding: 2px 8px;
                border: 1px solid var(--borderColor);
                border-radius: 30px;
                display: inline-block;
                background: #e3e6ea;
                cursor: pointer;
                -moz-user-select: none;
                -webkit-user-select: none;
                user-select: none;
                transition: all .3s cubic-bezier(.645,.045,.355,1);
            }
            &.ant-radio-wrapper-checked{
                span{
                    background: var(--primaryHover);
                    color: var(--blue);
                }
            }
        }
    }
}
.reg_form{
    @media (max-height: 750px) {
        padding-top: 110px;
    }
}

.buttons_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.button_next {
    grid-column: span 2 / span 2;
    @media (max-width: $breakpoint-1) {
        grid-column: span 1 / span 1;
    }
}
.user_info {
    &::v-deep {
        .ant-input,
        .ant-btn {
            height: 50px;
        }
    }
}
</style>